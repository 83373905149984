<template>
</template>

<script>
export default {
    props: [],
    components: {
    },
    data () {
        return {
        };
    },
    watch: {
    },
    methods: {
    },
    created(){
    },
    mounted() {
    }
}
</script>

<style>

</style>